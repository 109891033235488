exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-portfolio-minimal-src-pages-404-tsx": () => import("./../../../node_modules/gatsby-theme-portfolio-minimal/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-minimal-src-pages-404-tsx" */),
  "component---src-gatsby-theme-portfolio-minimal-templates-article-index-tsx": () => import("./../../../src/gatsby-theme-portfolio-minimal/templates/Article/index.tsx" /* webpackChunkName: "component---src-gatsby-theme-portfolio-minimal-templates-article-index-tsx" */),
  "component---src-gatsby-theme-portfolio-minimal-templates-article-listing-index-tsx": () => import("./../../../src/gatsby-theme-portfolio-minimal/templates/ArticleListing/index.tsx" /* webpackChunkName: "component---src-gatsby-theme-portfolio-minimal-templates-article-listing-index-tsx" */),
  "component---src-pages-gallery-2023-js": () => import("./../../../src/pages/gallery/2023.js" /* webpackChunkName: "component---src-pages-gallery-2023-js" */),
  "component---src-pages-gallery-fringe-2024-1-js": () => import("./../../../src/pages/gallery/fringe-2024/1.js" /* webpackChunkName: "component---src-pages-gallery-fringe-2024-1-js" */),
  "component---src-pages-gallery-fringe-2024-2-js": () => import("./../../../src/pages/gallery/fringe-2024/2.js" /* webpackChunkName: "component---src-pages-gallery-fringe-2024-2-js" */),
  "component---src-pages-gallery-fringe-2024-3-js": () => import("./../../../src/pages/gallery/fringe-2024/3.js" /* webpackChunkName: "component---src-pages-gallery-fringe-2024-3-js" */),
  "component---src-pages-gallery-fringe-2024-4-js": () => import("./../../../src/pages/gallery/fringe-2024/4.js" /* webpackChunkName: "component---src-pages-gallery-fringe-2024-4-js" */),
  "component---src-pages-gallery-fringe-2024-5-js": () => import("./../../../src/pages/gallery/fringe-2024/5.js" /* webpackChunkName: "component---src-pages-gallery-fringe-2024-5-js" */),
  "component---src-pages-gallery-fringe-portrait-laiyee-js": () => import("./../../../src/pages/gallery/fringe-portrait-laiyee.js" /* webpackChunkName: "component---src-pages-gallery-fringe-portrait-laiyee-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

